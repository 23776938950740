<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialogQuestionVWR"
        persistent
        max-width="1400px"
        scrollable
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form_question_vwr === 'add'" class="headline"
                >Thêm câu hỏi (VWR)</span
              >
              <span v-if="type_form_question_vwr === 'update'" class="headline"
                >Cập nhật câu hỏi (VWR)</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogQuestionVWR = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="2">
                  <p class="text-h6 black--text">Title:</p></v-col
                >
                <v-col cols="6">
                  <EditorBalloon v-model="title_input" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <p class="text-h6 black--text">Description:</p></v-col
                >
                <v-col cols="6">
                  <EditorBasic v-model="description_input" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <p class="text-h6 black--text">Chọn loại câu hỏi:</p></v-col
                >
                <v-col cols="6">
                  <v-select
                    :items="writingPartTypeList"
                    label="Bấm để chọn"
                    item-text="title"
                    item-value="code"
                    required
                    outlined
                    dense
                    v-model="writing_part_type_input"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2"></v-col>
                <v-col cols="5">
                  <v-select
                    :items="weight_options"
                    item-value="value"
                    item-text="label"
                    label="Weight"
                    required
                    outlined
                    dense
                    v-model="weight_paragraph"
                  ></v-select>
                </v-col>
                <v-col cols="5" v-if="product_category_id === product_category_const.TOEIC">
                  <v-text-field
                    label="Min words"
                    required
                    outlined
                    dense
                    type="number"
                    v-model="min_words"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
                  class="mt-0"
                  v-if="skills[indexSkill]?.skill_type === 'Writing' && skills[indexSkill]?.vwr === 1"
                >
                <v-col cols="2"></v-col>
                <v-col cols="5">
                  <label style="font-size: 16px" class="mt-1 p-0"
                  >Giới hạn thời gian (phút : giây):
                  </label>
                  <date-picker
                      class="ml-2"
                      v-model="limit_time"
                      type="time"
                      format="mm:ss"
                      value-type="format"
                      placeholder="mm:ss"
                  ></date-picker>
                </v-col>
              </v-row>
              <template
                v-for="(structure_question, iStructure) in structure_questions"
              >
                <v-row :key="iStructure" class="mb-2">
                  <v-col cols="2">
                    <p class="text-h6 black--text">
                      {{ structure_question.name }}
                    </p>
                  </v-col>
                  <v-col cols="10">
                    <template
                      v-for="(
                        component, iComponent
                      ) in structure_question.components"
                    >
                      <div style="width: 100%" :key="iComponent" class="mb-5">
                        <p class="text-h6 black--text mb-0">
                          {{ component.name }}
                        </p>
                        <template v-if="component.has_children">
                          <template
                            v-for="(children, iChildren) in component.children"
                          >
                            <div
                              style="width: 100%"
                              class="pl-5 mb-5"
                              :key="iChildren"
                            >
                              <p class="text-h6 black--text mb-0">
                                {{ children.name }}
                              </p>
                              <div style="width: 100%" class="ml-5">
                                <p class="text-subtitle-1 black--text mb-0">
                                  Outline:
                                </p>
                                <EditorBalloon v-model="children.outline" />
                              </div>
                              <div style="width: 100%" class="ml-5 mt-5">
                                <p class="text-subtitle-1 black--text mb-0">
                                  Sample:
                                </p>
                                <EditorBalloon v-model="children.sample" />
                              </div>
                            </div>
                          </template>
                        </template>
                        <template v-if="!component.has_children">
                          <div style="width: 100%" class="ml-5">
                            <p class="text-subtitle-1 black--text mb-0">
                              Outline:
                            </p>
                            <EditorBalloon
                              style="max-height: 200px"
                              v-model="component.outline"
                            />
                          </div>
                          <div style="width: 100%" class="ml-5 mt-5">
                            <p class="text-subtitle-1 black--text mb-0">
                              Sample:
                            </p>
                            <EditorBalloon v-model="component.sample" />
                          </div>
                        </template>
                      </div>
                    </template>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darken-1" text @click="dialogQuestionVWR = false"
              >Đóng</v-btn
            >
            <v-btn
              outlined
              class="font-weight-bold"
              color="blue darken-1"
              text
              v-if="type_form_question_vwr === 'add'"
              @click="btnAddQuestion"
              >Thêm</v-btn
            >
            <v-btn
              outlined
              class="font-weight-bold"
              color="blue darken-1"
              text
              v-if="type_form_question_vwr === 'update'"
              @click="btnEditQuestion"
              >Cập nhật</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ApiService from "@/service/api.service";
import weightSupport from "./Support/Question/weightSupport";
import productCategorySupport from "./Support/Test/productCategorySupport";

export default {
  name: "QuestionVWRModal",
  components: {
    EditorBalloon: () => import("../../../../components/ckeditor5/EditorBalloon"),
    // Editor: () => import('../../../../components/ckeditor5/Editor'),
    EditorBasic: () => import("../../../../components/ckeditor5/EditorBasic"),
  },
  mixins: [
      weightSupport,
      productCategorySupport,
  ],
  data() {
    return {
      structure_questions: [],
      data_type_question: null,
      writing_part_id: null,
      writing_part_type_input: null,
      writingPartTypeList: [],
      title_input: "",
      description_input: "",
      // weight_options: ['Writing Task 1', 'Writing Task 2', 'TOEIC Writing'],
      weight_paragraph: null,
      min_words: "",
      limit_time: "",
    };
  },
  computed: {
    ...mapState({
      type_form_question_vwr: (state) =>
        state.contentTestBuilderStore.type_form_question_vwr,
      exercise_id: (state) => state.contentTestBuilderStore.exercise_id,
      question_id: (state) => state.contentTestBuilderStore.question_id,
      indexSkill: (state) => state.contentTestBuilderStore.indexSkill,
      indexSection: (state) => state.contentTestBuilderStore.indexSection,
      indexExercise: (state) => state.contentTestBuilderStore.indexExercise,
      indexQuestion: (state) => state.contentTestBuilderStore.indexQuestion,
      product_category_id: (state) => state.informationTestBuilderStore.product_category_id,
    }),
    ...mapGetters({
      skills: "contentTestBuilderStore/skills",
    }),
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    dialogQuestionVWR: {
      get() {
        return this.$store.state.contentTestBuilderStore.dialogQuestionVWR;
      },
      set(value) {
        this.$store.commit(
          "contentTestBuilderStore/setDialogQuestionVWR",
          value
        );
      },
    },
    hostCurrent() {
      return window.location.host;
    },
  },
  watch: {
    dialogQuestionVWR(val) {
      if (val) {
        this.getWritingPartTypeList();
        this.setDataInput();

        if (this.type_form_question_vwr === 'add') {
          let validateQuestionLimit = this.validateQuestionLimit();
          if (!validateQuestionLimit) {
            this.$nextTick(() => {
              this.dialogQuestionVWR = false;
            })
          }
        }
      } else {
        this.writing_part_id = null;
        this.structure_questions = [];
      }
    },
  },
  methods: {
    setDataInput() {
      this.data_type_question = this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].exercise_type;
      if (this.type_form_question_vwr === "add") {
        this.title_input = "";
        this.description_input = "";
        this.writing_part_type_input = null;

        this.min_words = null;
        if (this.product_category_id === this.product_category_const.IELTS) {
          this.limit_time = "40:00";
          this.weight_paragraph = this.getValueByLabelWeight("Writing Task 2");
        } else {
          this.limit_time = "30:00";
          this.weight_paragraph = this.getValueByLabelWeight("Writing Task 1");
        }
        this.getStructureQuestionVWR();
      }
      if (this.type_form_question_vwr === "update") {
        let data = this.skills[this.indexSkill].sections[this.indexSection].exercises[this.indexExercise].questions[this.indexQuestion];
        this.title_input = data.title;
        this.description_input = data.description ? data.description : "";
        this.writing_part_type_input = data.writing_part_type;
        this.structure_questions = data.writing_part;
        this.writing_part_id = data.writing_part_id;
        // this.min_words = data.validation_rules ? data.validation_rules.min_words : null;
        this.min_words = this.checkDisplayValidationRules(data, 'min_words');
        this.limit_time = this.checkDisplayValidationRules(data, 'limit_time');
        this.weight_paragraph = data.weight;
        this.is_call_api = false;
      }
    },
    async getWritingPartTypeList() {
      let vm = this;
      try {
        // let url = '';
        // if (this.product_category_id === this.product_category_const.IELTS) {
        //   url = 'prep-app/test/draft/question/ielts-writing-part-one-type-list';
        // } else {
        //   url = 'prep-app/test/draft/question/writing-part-type-list';
        // }
        let res = await ApiService.get('prep-app/test/draft/question/writing-part-type-list');
        if (res.status === 200) {
          vm.writingPartTypeList = res.data;
          if (vm.product_category_id === vm.product_category_const.IELTS) {
            vm.writingPartTypeList = vm.writingPartTypeList.filter((obj) => {
              return obj.code !== 'opinion_essay_toeic';
            })
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getStructureQuestionVWR() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get("prep-app/test/draft/question/structure-vwr");
        if (res.status === 200) {
          vm.structure_questions = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    validateFormQuestion() {
      let flat = true;
      let skill = this.skills[this.indexSkill];
      if (this.title_input == null || this.title_input === "") {
        this.$toasted.error("Bạn chưa nhập tiêu đề question !!", {
          theme: "bubble",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.writing_part_type_input == null || this.writing_part_type_input === "") {
        this.$toasted.error("Bạn chọn loại câu hỏi !!", {
          theme: "bubble",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }

      if (skill.skill_type === 'Writing' && skill.vwr === 1) {
        if (this.product_category_id === this.product_category_const.TOEIC) {
          if (this.weight_paragraph !== this.getValueByLabelWeight('TOEIC Writing')) {
            this.$toasted.error("Vui lòng chọn thông tin weight phù hợp với bài test VWR TOEIC", {theme: "bubble", position: "top-right", duration: 4000,});
            flat = false;
          }
          if (this.writing_part_type_input !== "opinion_essay_toeic") {
            this.$toasted.error("Vui lòng chọn loại câu hỏi phù hợp với bài test VWR TOEIC", {theme: "bubble", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
        if (this.product_category_id === this.product_category_const.IELTS) {
          if (this.weight_paragraph != this.getValueByLabelWeight('Writing Task 2')) {
            this.$toasted.error("Vui lòng chọn thông tin weight phù hợp với bài test VWR Ielts", {theme: "bubble", position: "top-right", duration: 4000,});
            flat = false;
          }
        }
      }

      return flat;
    },
    async btnAddQuestion() {
      let validateForm = this.validateFormQuestion();
      if (!validateForm) {
        return;
      }
      let vm = this;
      let data = {
        exercise_id: this.exercise_id,
        exercise_type: this.data_type_question,
        type: this.data_type_question,
        writing_part: this.structure_questions,
        title: this.title_input,
        writing_part_type: this.writing_part_type_input,
        validation_rules: {
          min_words: this.min_words,
          limit_time: this.limit_time ? this.$options.filters.convertMSToSecond(this.limit_time) : null,
        },
        weight: this.weight_paragraph,
        limit_time: this.limit_time ? this.$options.filters.convertMSToSecond(this.limit_time) : null,
      };
      if (this.description_input) data.description = this.description_input;
      this.is_call_api = true;
      await ApiService.post("prep-app/test/draft/question", data)
        .then(function (res) {
          if (res.status === 200) {
            vm.handleAfterCreate(data, res);
            vm.dialogQuestionVWR = false;
            vm.$toasted.success("Them thanh cong", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            vm.is_call_api = false;
          }
        })
        .catch(function (error) {
          if (error) {
            vm.dialogQuestionVWR = false;
            vm.is_call_api = false;
            vm.$toasted.error(
              "Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ",
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              }
            );
          }
        });
    },
    handleAfterCreate(data, res) {
      let vm = this;
      let dataCheck =
        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[
          vm.indexExercise
        ].questions;
      if (dataCheck === undefined || dataCheck === null) {
        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[
          vm.indexExercise
        ].questions = [];
      }
      data.id = res.data.question_id;
      data.writing_part_id = res.data.writing_part_id;
      let indexRowQuestion =
        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[
          vm.indexExercise
        ].questions.push(data) - 1;
      for (
        let indexExer =
          vm.skills[vm.indexSkill].sections[vm.indexSection].exercises.length -
          1;
        indexExer >= vm.indexExercise + 1;
        indexExer--
      ) {
        vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[indexExer]
          .offset_question++;
      }
      for (
        let indexSec = vm.skills[vm.indexSkill].sections.length - 1;
        indexSec >= vm.indexSection + 1;
        indexSec--
      ) {
        vm.skills[vm.indexSkill].sections[indexSec].offset_question++;
      }
      vm.skills[vm.indexSkill].sections[vm.indexSection].total_question++;
      vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[
        vm.indexExercise
      ].total_question++;
      vm.$set(vm.skills);
      vm.is_call_api = false;
      vm.dialogQuestion = false;
      setTimeout(() => {
        vm.$scrollTo(
          "#question" +
            vm.indexSkill +
            vm.indexSection +
            vm.indexExercise +
            indexRowQuestion,
          1000
        );
      }, 100);
    },
    async btnEditQuestion() {
      let validateForm = this.validateFormQuestion();
      if (!validateForm) {
        return;
      }
      let vm = this;
      let data = {
        id: this.question_id,
        exercise_id: this.exercise_id,
        exercise_type: this.data_type_question,
        type: this.data_type_question,
        writing_part: this.structure_questions,
        writing_part_id: this.writing_part_id,
        title: this.title_input,
        writing_part_type: this.writing_part_type_input,
        validation_rules: {
          min_words: this.min_words,
          limit_time: this.limit_time ? this.$options.filters.convertMSToSecond(this.limit_time) : null,
        },
        weight: this.weight_paragraph,
        limit_time: this.limit_time ? this.$options.filters.convertMSToSecond(this.limit_time) : null,
      };
      if (this.description_input) data.description = this.description_input;
      this.is_call_api = true;
      await ApiService.put(
        "prep-app/test/draft/question/" + this.question_id,
        data
      )
        .then(function (res) {
          if (res.status === 200) {
            console.log(res);
            vm.skills[vm.indexSkill].sections[vm.indexSection].exercises[
              vm.indexExercise
            ].questions[vm.indexQuestion] = data;
            vm.$set(vm.skills);
            vm.is_call_api = false;
            vm.$toasted.success("Cap nhap thanh cong", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            vm.dialogQuestionVWR = false;
          }
        })
        .catch(function (error) {
          if (error) {
            vm.dialogQuestionVWR = false;
            vm.is_call_api = false;
            vm.$toasted.error(
              "Có lỗi xảy ra. Vui lòng liên hệ phòng kỹ thuật hỗ trợ",
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              }
            );
          }
        });
    },
    checkDisplayValidationRules(question, field) {
      let data = null;
      if (question.validation_rules) {
        if (question.validation_rules[field]) {
          if(field === 'limit_time') {
            data = this.$options.filters.convertSecondToMS(question.validation_rules[field]);
          } else {
            data = question.validation_rules[field];
          }
        }

      }
      return data;
    },
    validateQuestionLimit() {
      let flat = true;
      let currentSection = this.skills[this.indexSkill].sections[this.indexSection];
      if (currentSection.question_limit != null) {
        let countQuestionBySection = currentSection.exercises.reduce((totalQuestion, exercise) => {
          let questionLength = exercise.questions !== undefined ? exercise.questions.length : 0;
          return totalQuestion + questionLength;
        },0);
        if (countQuestionBySection >= currentSection.question_limit) {
          this.$toasted.error("Số câu hỏi tối đa của phần này là "+currentSection.question_limit+" câu", {theme: "toasted-primary", position: "top-right", duration: 4000,});
          flat = false;
        }
      }
      return flat;
    },
  },
};
</script>

<style scoped></style>
